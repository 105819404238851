(<template>
  <verify-email />
</template>)

<script>
  import WizardVerifyEmail from '@/components/wizard_components/WizardVerifyEmail';

  export default {
    components: {
      'verify-email': WizardVerifyEmail
    }
  };
</script>
