// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/wizard_icons/verify-email.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.verify-email[data-v-2915c180] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  padding: 115px 40px 30px;\n  background-color: #fff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 0;\n  background-repeat: no-repeat;\n  text-align: center;\n}\n.verify-email__title[data-v-2915c180] {\n  margin-bottom: 25px;\n  font-weight: bold;\n  font-size: 16px;\n}\n.verify-email__text[data-v-2915c180] {\n  margin-bottom: 10px;\n  font-size: 12px;\n}\n.verify-email__text--bold[data-v-2915c180] {\n  font-weight: bold;\n}\n.verify-email__btn-wrapper[data-v-2915c180] {\n  width: 250px;\n  margin-top: 10px;\n}\n.verify-email__contact[data-v-2915c180] {\n  width: 100%;\n  margin: auto;\n}\n.verify-email__send-email[data-v-2915c180] {\n  width: 100%;\n  margin: 0 auto 20px;\n}\n@media (max-width: 400px) {\n.verify-email[data-v-2915c180] {\n    padding: 20px;\n    padding-top: 115px;\n}\n.verify-email__btn-wrapper[data-v-2915c180] {\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    margin-top: 15px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
