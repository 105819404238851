(<template>
  <form-template :progress="progressActive">
    <article class="verify-email"
             @keydown.enter.stop.prevent="sendEmail">
      <p class="verify-email__title">{{ $gettext('Please verify your e-mail address to activate your platform account.') }}</p>
      <p class="verify-email__text verify-email__text--bold">{{ $gettext('We have sent you an email please go to your email client!') }}</p>
      <p class="verify-email__text">{{ $gettext('If you have not received a verification email, please check your spam folder or click here to receive a new verification email.') }}</p>
      <div class="verify-email__btn-wrapper">
        <button class="sk-btn sk-btn--white verify-email__send-email"
                @click="sendEmail">{{ $gettext('Send new verification email') }}</button>
        <button class="sk-btn sk-btn--default verify-email__contact"
                @click="openHelpModal">{{ $gettext('Contact us') }}</button>
      </div>
    </article>
  </form-template>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';

  export default {
    components: {
      'form-template': WizardFormTemplate
    },
    data() { return {progressActive: false}; },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapState('UserInfoStore', {
        emailVerified: ({mainUserInfo}) => mainUserInfo.emailVerified
      }),
      title() { return this.$gettext('Please verify your account'); }
    },
    watch: {
      emailVerified() {
        if (this.emailVerified) {
          window.location.reload();
        }
      }
    },
    methods: {
      openHelpModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'help-modal',
          data: {
            title: this.$gettext('Contact us'),
            context: this
          }
        });
      },
      sendEmail() {
        this.progressActive = true;
        this.$store.dispatch('sendVerifyEmail').then(() => {
          this.progressActive = false;
        });
      }
    },
    mounted() {
      this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
        if (this.emailVerified) {
          window.location.reload();
        }
      });
    }
  };
</script>

<style scoped>
  .verify-email {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 115px 40px 30px;
    background-color: #fff;
    background-image: url(~@assets/imgs/wizard_icons/verify-email.svg);
    background-position: 50% 0;
    background-repeat: no-repeat;
    text-align: center;
  }

  .verify-email__title {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 16px;
  }

  .verify-email__text {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .verify-email__text--bold {
    font-weight: bold;
  }

  .verify-email__btn-wrapper {
    width: 250px;
    margin-top: 10px;
  }

  .verify-email__contact {
    width: 100%;
    margin: auto;
  }

  .verify-email__send-email {
    width: 100%;
    margin: 0 auto 20px;
  }

  @media (max-width: 400px) {
    .verify-email {
      padding: 20px;
      padding-top: 115px;
    }

    .verify-email__btn-wrapper {
      flex-wrap: wrap;
      margin-top: 15px;
    }
  }
</style>
