import { render, staticRenderFns } from "./WizardVerifyEmail.vue?vue&type=template&id=2915c180&scoped=true&"
import script from "./WizardVerifyEmail.vue?vue&type=script&lang=js&"
export * from "./WizardVerifyEmail.vue?vue&type=script&lang=js&"
import style0 from "./WizardVerifyEmail.vue?vue&type=style&index=0&id=2915c180&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2915c180",
  null
  
)

export default component.exports